/* eslint-disable no-alert */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-underscore-dangle */
/* eslint-disable newline-per-chained-call */
import React from 'react';
import { H2 } from '@allthingswww/client-act-shared';
import MUIDataTable from 'mui-datatables';

import Layout from '../components/Layout';
import attendeesTableBuilder from '../components/MemberArea/MemberPages/Builders/attendeesTableBuilder';

export default (props) => {
  const { amountToBePaidDetails } = props.location.state || [];
  const formattedAmountToBePaidDetails = amountToBePaidDetails?.map((item) => {
    let eventItems = '';
    if (item.eventItems) {
      Object.keys(item.eventItems).map((val) => {
        eventItems += `${item.eventItems[val].values[0]} | ${val} - `;
        return eventItems;
      });
    }
    return { ...item, eventItems };
  }) || [];

  const muiDataTableBuilder = attendeesTableBuilder()
    .setData(formattedAmountToBePaidDetails)
    .getColumnItemByKey('fullName')
    .show()
    .moveToEnd()
    .build()

    .getColumnItemByKey('email')
    .show()
    .moveToEnd()
    .build()

    .getColumnItemByKey('phoneNumber')
    .show()
    .moveToEnd()
    .build()

    .getColumnItemByKey('address')
    .show()
    .moveToEnd()
    .build()

    .getColumnItemByKey('eventItems')
    .show()
    .moveToEnd()
    .build()

    .sortVisible()
    .showDownload(true)
    .applyOptions({
      filterType: 'checkbox',
      selectableRows: 'multiple',
    });

  return (
    <Layout title="Registered attendees" subtitle="">
      <H2>Registered attendees</H2>
      <MUIDataTable
        title="Registered attendees"
        data={formattedAmountToBePaidDetails}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...muiDataTableBuilder.build()}
      />
    </Layout>
  );
};
