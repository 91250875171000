import { ColumnBuilder, ColumnItem } from '../muiColumnBuilder';

export default () => new ColumnBuilder([
  new ColumnItem('fullName', 'Full name'),
  new ColumnItem('email', 'Email'),
  new ColumnItem('address', 'Address'),
  new ColumnItem('phoneNumber', 'Phone number'),
  new ColumnItem('eventItems', 'Event Items Bought'),
])
  .showPrint(false);
